import {CacheFirst} from 'workbox-strategies';
import {registerRoute, Route} from 'workbox-routing';
import {setCacheNameDetails} from 'workbox-core';
import {ExpirationPlugin} from 'workbox-expiration'
import {CacheableResponsePlugin} from 'workbox-cacheable-response';


/*   -----   Init  -----   */
// Set default cache names
setCacheNameDetails({
    prefix: 'dst',
    suffix: 'v1',
    precache: 'install-time',
    runtime: 'run-time',
    googleAnalytics: 'ga',
});

self.skipWaiting(); // Make service worker immediately usable

/*   -----  static-assets-cache-first  -----   */
registerRoute(
    new Route(
        ({event, request, sameOrigin, url}) => {
            if (['image'].includes(request.destination) && url.pathname.includes('/template_previews/')) {
                return true;
            }
        },
        new CacheFirst({
            cacheName: 'static-assets-cache-first',
            plugins: [
                new ExpirationPlugin({
                    maxAgeSeconds: 28800, // 8 hours
                    maxEntries: 1000,
                    purgeOnQuotaError: true,
                }),
                new CacheableResponsePlugin({
                    statuses: [0, 200]
                }),
            ],
        })
    )
);
